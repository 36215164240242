import React from 'react'

// Import Hooks 
import { useAntdBreakPoints } from '../../hooks/useAntdBreakPoints'

// Import Utils
import { getPadding } from '../../utils/utils'

// Import Constants
const SectionContainer = ({ children, id, containerStyles }) => {
    // Break Points
    const screens = useAntdBreakPoints()

    const padding = getPadding(screens)

    return (
        <div id={ id ?? '' } style={{ ...styles, ...containerStyles, padding: padding ? padding  : '40px 32px 40px 32px' }}>
            { children }
        </div>
    )
}

// Styles
const styles = {
    height: 'auto',
    width: '100%',
    margin: '0 auto'
}

export default SectionContainer