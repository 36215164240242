import React from "react";

// Import Components
import { Row, Col, Button } from "antd";
import ContactOption from "./ContactOption";
import FeedbackForm from "./FeedbackForm";
import SmallHeading from "../common/SmallHeading";
import SectionContainer from "../common/SectionContainer";

// Import Public URL
import { PUBLIC_URL } from "../../App.config";

const FeedBack = () => {
  return (
    <div style={{ ...bgStyles, scrollBehavior: "smooth" }} id={"feedback"}>
      <SectionContainer>
        <Row
          gutter={[64, 32]}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col lg={24}>
            <div
              style={{ ...innerContainerStyles, padding: "0px 0px 0px 0px" }}
            >
              <SmallHeading
                first={"Ready to experience Harmony ?"}
                last={"Reach out to us!"}
              />
            </div>
          </Col>
          <Col xs={24} sm={20} md={20} lg={10}>
            <div style={{ ...innerContainerStyles }}>
              <div style={formContainerStyles}>
                <div
                  style={{
                    padding: "16px 0px 0px 0px",
                  }}
                >
                  <SmallHeading first={"Request a"} last={"Demo"} />
                </div>
                <div style={{ width: "100%", height: "100%" }}>
                  <FeedbackForm />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div
              style={{
                ...innerContainerStyles,
                alignItems: "flex-start",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div>
                <SmallHeading first={"Have any"} last={"Question ?"} />
              </div>
              <div>
                <div>
                  <span style={textStyles}>
                    If you have any question about our product, service, payment
                    or company - Visit :
                    <Button
                      style={linkStyles}
                      type="link"
                      href="https://www.barikoi.com/"
                      target="_blank"
                    >
                      {`“www.barikoi.com”`}
                    </Button>
                  </span>
                </div>
              </div>
              <ContactOption
                image={PUBLIC_URL + "/images/feedback/icon-2.png"}
                contact={"Email Us"}
                value={"hello@barikoi.com"}
              />
              <ContactOption
                image={PUBLIC_URL + "/images/feedback/icon-3.png"}
                contact={"Call Us"}
                value={"+880 1708549077"}
              />
              <ContactOption
                image={PUBLIC_URL + "/images/feedback/icon-4.png"}
                contact={"Visit Us in Bangladesh"}
                value={
                  "Dr Mohsin Plaza, House 2/7, Begum Rokeya Sarani, Pallabi, Mirpur, Dhaka"
                }
              />
              <ContactOption
                image={PUBLIC_URL + "/images/feedback/icon-4.png"}
                contact={"Visit Us in Singapore"}
                value={"68 CIRCULAR ROAD, #02-01, SINGAPORE (049422)"}
              />
            </div>
          </Col>
        </Row>
      </SectionContainer>
    </div>
  );
};

// Styles
const bgStyles = {
  display: "flex",
  backgroundImage: `url('/images/feedback/feedback-bg.png')`,
  backgroundPosition: "bottom",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  padding: "0px 0px",
  width: "100%",
};

const innerContainerStyles = {
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: "0px",
};

const textStyles = {
  fontFamily: "'Roboto'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  color: "#272727",
};

const linkStyles = {
  fontFamily: "'Roboto'",
  fontWeight: 600,
  color: "#272727",
};

const formContainerStyles = {
  background: "#FFFFFF",
  boxShadow: "0px 0px 50px 2px rgba(132, 132, 132, 0.25)",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  minHeight: "100%",
  padding: "24px 32px",
  alignItems: "center",
  justifyContent: "center",
  gap: 32,
};

export default FeedBack;
