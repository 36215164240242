import React, { useEffect, useState } from "react";
import getUserCountry from "js-user-country";
import Link from "antd/es/typography/Link";

// Import Components
import { Row, Col, Typography } from "antd";
import PlanCard from "./PlanCard";
import SmallHeading from "../common/SmallHeading";
import SectionContainer from "../common/SectionContainer";

// Import Public URl
import { PUBLIC_URL } from "../../App.config";
import PlanSlider from "./PlanSlider";

// Import Constants
const { Text } = Typography;

const Plans = () => {
  const [country, setCountry] = useState("");

  const plans = [
    {
      image: PUBLIC_URL + "/images/plans/plan1-img.png",
      name: "SIMPLE",
      note: "-",
      price: country === "Bangladesh" ? "40" : "0.40",
      scheme: "Per User/Month",
      informations: {
        "For up to 10 users": true,
        Announcement: true,
        "Expense/Leave Request": false,
        Payroll: false,
        "Face Detection": false,
        "Task Management": false,
        "Real Time Tracking": false,
        "Free Support": true,
      },
    },
    {
      image: PUBLIC_URL + "/images/plans/plan2-img.png",
      name: "PRO",
      note: "Ideal for Startups",
      price: country === "Bangladesh" ? "60" : "0.60",
      scheme: "Per User/Month",
      informations: {
        "70 User": true,
        Announcement: true,
        "Expense/Leave Request": true,
        Payroll: false,
        "Face Detection": false,
        "Task Management": false,
        "Real Time Tracking": false,
        "Free Support": true,
      },
    },
    {
      image: PUBLIC_URL + "/images/plans/plan3-img.png",
      name: "ADVANCE",
      note: "Best for big teams",
      price: country === "Bangladesh" ? "100" : "1",
      scheme: "Per User/Month",
      informations: {
        "For Unlimited Users": true,
        Announcement: true,
        "Expense/Leave Request": true,
        Payroll: false,
        "Face Detection": false,
        "Task Management": true,
        "Real Time Tracking": false,
        "Free Support": true,
      },
    },
  ];

  // On Get Country Info
  const _onGetCountryInfo = () => {
    const country = getUserCountry()?.name ?? "";
    setCountry(country);
  };

  useEffect(() => {
    _onGetCountryInfo();
  }, []);

  return (
    <div style={bgStyles} id={"pricing"}>
      <SectionContainer style={{ gap: 32 }}>
        <Row>
          <div style={innerContainerStyles}>
            <SmallHeading first={"Your payment plans"} last={"with Harmony"} />
          </div>
          <Col span={24}>
            <div style={innerContainerStyles}>
              <div
                style={{
                  display: "flex",
                  borderRadius: "10px",
                  padding: "16px 32px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                {/* <Anchor> */}
                <Link href={"#feedback"} style={{ textAlign: "center" }}>
                  <Text style={textStyles}>
                    {
                      "Do you have a small business? Try Harmony, now FREE for everyone."
                    }
                  </Text>
                </Link>
                {/* </Anchor> */}
              </div>
            </div>
          </Col>
          <Col lg={24}>
            <PlanSlider />
          </Col>
          <Col lg={24}>
            <div style={{ ...innerContainerStyles, gap: 4 }}>
              <Row gutter={[64, 32]} justify="center" style={{ flex: 1 }}>
                {/* {plans?.map((p, idx) => (
                  <Col key={idx} xs={24} sm={18} md={10} lg={10} xl={7} xxl={6}>
                    <PlanCard
                      image={p?.image}
                      name={p?.name ?? ""}
                      note={p?.note ?? ""}
                      price={p?.price ?? ""}
                      scheme={p?.scheme ?? ""}
                      informations={p?.informations ?? {}}
                      isActive={idx % 2 !== 0}
                      symbol={country === "Bangladesh" ? "৳" : "$"}
                    />
                  </Col>
                ))} */}
              </Row>
            </div>
          </Col>
          <Col lg={24}>
            <div style={{ ...innerContainerStyles, flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  borderRadius: "10px",
                  padding: "16px 32px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                <Link href={"#feedback"} style={{ textAlign: "center" }}>
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={textStyles}>
                        {
                          "Real time tracking will be calculated on a usage basis. Per user per day is 10tk enabled upon attendance."
                        }
                      </Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={textStyles}>
                        {
                          "For employers with 1000+ traceable employees, a bulk discount is applicable."
                        }
                      </Text>
                    </div>
                  </>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </SectionContainer>
    </div>
  );
};

// Styles
const bgStyles = {
  display: "flex",
  paddding: "0px 0px",
  width: "100%",
};

const innerContainerStyles = {
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: "0px 0px 50px 0px",
};

const textStyles = {
  fontFamily: "'Roboto'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  color: "#272727",
  width: "100%",
};

export default Plans;
