import axios from 'axios'
import { API } from '../App.config'

// Request Demo
export async function requestDemo(data) {
    const response = await axios.post(`${ API.REQUEST_DEMO }`, data )
    return response
}

// Fetch our clients Data 
export async function getClients() {
    const response = await axios.get(`${ API.GET_CLIENTS }`)
    return response
}