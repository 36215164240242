export const getPadding = (screens) => {
    const { xs, sm, md, lg, xl, xxl } = screens

    if(xs){
        return '40px 32px 40px 32px'
    } else if(sm) {
        return '40px 32px 40px 32px'
    } else if(md) {
        return '40px 48px 40px 48px'
    } else if (lg) {
        return '40px 64px 40px 64px'
    } else if (xl) {
        return '40px 72px 40px 72px'
    } else if (xxl){
        return '40px 195px 40px 195px'
    }
}