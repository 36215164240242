import React from 'react'

// Import Components
import { Row, Col, Typography, Button } from 'antd'
import SectionContainer from '../common/SectionContainer'
import SmallHeading from '../common/SmallHeading'

// Import Hooks 
import { useAntdBreakPoints } from '../../hooks/useAntdBreakPoints'
import { PUBLIC_URL } from '../../App.config'
// import Banner from "../../assets/images/about/app_store_banner.png"

// Import Constants
const { Paragraph } = Typography

const About = () => {

    // Break Points
    const { xs, sm, md, lg, xl } = useAntdBreakPoints()
    const xsSm = (xs || sm)
    const textWidth =  xs ? '100%' : sm ? '100%' : md ? '100%' : lg ? '75%' : xl ? '75%' : '75%' 

    return (
        <SectionContainer id={ 'about' }>
            <Row 
                justify={ 'center' } 
                align={ 'center' } 
                gutter={[ 32, 32 ]} 
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: xsSm ? 'column' : 'row' }}
            >
                {/* Left */}
                <Col lg={12}>
                    {/* Banner Image */}
                    <img style={{ maxWidth: '100%', height: 'auto' }} src={ PUBLIC_URL + '/images/about/app_store_banner.png' } alt="app-store" />
                    {/* <img style={{ maxWidth: '100%', height: 'auto' }}  src={Banner} alt="mockup" /> */}
                </Col>

                {/* Right */}
                <Col lg={12} style={rightContainerStyles}>
                    {/* Heading */}
                    <div style={{ width: textWidth, display: 'flex', justifyContent: 'center' }}>
                        <SmallHeading first={'Why choose'} last={'Harmony for your workplace ?'} />
                    </div>

                    {/* Paragraph */}
                    <div style={{marginTop: '15px'}}>
                        <Paragraph style={textStyles}>
                            Harmony is a tech-enabled platform for easy office employee management automating attendance, clocking-in to work, leave and expense approvals, and location records for field visit employees. The field-force monitoring feature uses map-enabled address data which allows tracking field force in real time to ensure effective service delivery; the service points are also geotagged as per requirement to ensure that the remote field employees have completed tasks at the designated location and are always within your reach.
                        </Paragraph>
                        <Paragraph style={textStyles}>
                        It is a user-friendly platform that makes employee management and monitoring easier than ever before and is completely customizable to your requirements!
                        </Paragraph>
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <Typography style={ downloadAppTextStyles }>{ 'Download Our App' }</Typography>
                    </div>

                    {/* Play Store */}
                    <div style={{ ...playStoreWrapperStyles, flexDirection: xsSm ? 'column' : 'row', gap: xsSm ? 64 : 24 }}>
                        <Button 
                            style={{ width: 'auto', maxWidth: '100%' }} 
                            href={ 'https://play.google.com/store/apps/details?id=com.barikoi.hrtraceapp&hl=en&gl=US' } 
                            target='_blank' 
                            type={ 'link' } 
                            icon={ <img width={ 'auto'} height={ 'auto' } src={ PUBLIC_URL + '/images/logo/play_store_logo.png' } alt={ 'playstore-log' } /> }
                        />
                        <Button 
                            style={{ width: 'auto', maxWidth: '100%' }} 
                            href={ 'https://apps.apple.com/us/app/harmony-hr-solution/id6443644004' } 
                            target='_blank' 
                            type={ 'link' } 
                            icon={ <img width={ 'auto '} height={ 'auto' } src={ PUBLIC_URL + '/images/logo/app_store_logo.png' } alt={ 'apple-store' } /> }
                        />
                    </div>
                </Col>
            </Row>
        </SectionContainer>
    )
}

// Styles

const rightContainerStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
}

const textStyles = {
    color: '#272727',
    fontSize: '16px',
    fontWeight: '400',
    textAlign: 'left'
}

const playStoreWrapperStyles = {
    display: 'flex',
    maxWidth: '100%'
}

const downloadAppTextStyles = {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    color: "#272727"
}

export default About;