import React from 'react'
import { Typography, Space } from 'antd'
import { useAntdBreakPoints } from '../../hooks/useAntdBreakPoints'

const SmallHeading = ({first, last}) => {
    const { xs, sm, md, lg, xl } = useAntdBreakPoints()
    const fontSize = xs ? '24px' : sm ? '24px' : md ? '28px' : lg ? '26px' : xl ? '30px' : '36px'
    const lineHeight =  xs ? '25px' : sm ? '26px' : md ? '30px' : lg ? '26px' : xl ? '32px' : '38px'

    return (
        <Space style={ containerStyles }>
            <Typography style={{ ...firstLineStyles, fontSize: fontSize, lineHeight: lineHeight }}>
                <span style={{ color: '#272727' }}>{ first }</span>
                <span> </span>
                <span style={{ color: '#02A66A' }}>{ last }</span>
            </Typography>
        </Space>
    );
};

// Styles
const firstLineStyles = {
    textAlign: 'left',
    color: '#272727',
    fontWeight: '500',
    lineHeight: '36px',
}

const containerStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4
}

export default SmallHeading;