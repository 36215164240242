import React from 'react'

// Import Components
import { Row, Col, Typography, Grid } from 'antd'

// Import Constants
const { Text } = Typography
const { useBreakpoint } = Grid

const Footer = () => {

     // Break Points
     const screens = useBreakpoint()
     const xs = Boolean( screens['xs'] === true )
     const sm = Boolean( screens['sm'] === true && screens['md'] === false )
     const md = Boolean( screens['md'] === true && screens['lg'] === false )
     const xsSm = (xs || sm || md )

    return (
        <div style={ imgBgStyles } id={ 'footer' }>
             <div style={{ ...constainerStyles, gap: 32, height: 'auto', padding: xsSm ? '30px 32px 30px 32px' : '30px 195px 30px 195px' }}>
                <Row style={{ width: '100%', display: 'flex', gap: 16, alignItems: 'center', justifyContent: 'center', flexDirection: xsSm ? 'column' : 'row' }}>
                    <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 12 } xxl={ 12 } style={{ flex: 1 }}>
                        <div style={{ ...innerContainerStyles, justifyContent: 'flex-start' }}>
                            <Text>{ '© Copyright 2023. All Rights Reserved' }</Text>
                        </div>
                    </Col>
                    <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 12 } xxl={ 12 } style={{ flex: 1 }}>
                        <div style={{ ...innerContainerStyles, justifyContent: 'flex-end' }}>
                            <Text>{ 'Design and Develop by Barikoi Technologies Ltd.' }</Text>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

// Styles
const imgBgStyles = {
    backgroundImage: `url('/images/footer/bg-image.png')`,
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    width: '100%',
    padding: '0px 0px'
}

const constainerStyles = {
    width: '100%',
    height: '60px',
    padding: '0px 195px 0 195px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

const innerContainerStyles = {
    display: 'flex', 
    width: '100%',
    gap: 32
}

export default Footer