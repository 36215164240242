import { Col, Row, Typography } from 'antd'
import React from 'react'

// Import Components
import SmallHeading from './common/SmallHeading'
import SectionContainer from './common/SectionContainer'

// Import Hooks 
import { useAntdBreakPoints } from '../hooks/useAntdBreakPoints'
import { PUBLIC_URL } from '../App.config'

// Import Constants
const { Title, Text, Paragraph } = Typography

const Feature = () => {

    // Break Points
    const { xs, sm, md } = useAntdBreakPoints()
    const xsSm = (xs || sm)
    const xsMd = (xs || sm || md)

    return (
        <SectionContainer id='features'>
            {/* Heading */}
            <div style={HeadingContainer}>
                <SmallHeading first={'Deep-diving into Harmony’s'} last={'Awesome Features'}/>
            </div>

            {/* Features */}
            <Row 
                gutter={[ 32, 32 ]} 
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: xsMd ? 'column-reverse' : 'row' }}
            >

                { !Boolean(xsSm || md) && <>
                        {/* 1st */}
                        
                        <Col lg={6}>
                            <div style={{width: 'auto' }}>
                                <Title style={TitleStyles} level={3}>High-performing teams communicate better</Title>
                                <Paragraph style={ParagraphStyles}>
                                    With Harmony, you can always have colleagues within reach thanks to the seamless interaction across the many cool features here
                                </Paragraph>
                            </div>
                        </Col>

                        {/* <Col lg={2}>
                            <div className='featuredImage' ></div>
                        </Col>  */}

                        {/* 2nd */}
                        <Col className='featuredImage' lg={{span: 8, offset: 0}} xl={{span: 6, offset: 2}} >
                            <div style={MiddleParagraphWrapper}>

                                <div className='featuredMiddleInfo' style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                    <div style={{width: 'auto'}}>
                                        <Title style={TitleStyles} level={3}>Sign-in to work with ease</Title>
                                        <Paragraph style={ParagraphStyles}>
                                            For both office and field employees creating worry-free clocking in. No RFID hardwares needed with Harmony’s minimal interface
                                        </Paragraph>
                                    </div>
                                </div>

                                <div className='featuredMiddleInfo' style={{width: 'auto'}}>
                                    <Title style={TitleStyles} level={3}>Simple location tagging</Title>
                                    <Paragraph style={ParagraphStyles}>
                                        Record field visits live from location and upload photos to validate making on field task reports more data-rich, intuitive and allow staff to reflect on their progress
                                    </Paragraph>
                                </div>

                                <div className='featuredMiddleInfo' style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                    <div style={{width: 'auto'}}>
                                        <Title style={TitleStyles} level={3}>Empower employees with self-service leave request processing</Title>
                                        <Paragraph style={ParagraphStyles}>
                                            Get easy leave seeking and approval processing with a few clicks. You and your employees can both forget paperwork
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </>
                }

                {/* Mobile and Tabs View */}
                { Boolean(xsSm || md) && <Col span={ 24 }>
                    <Row gutter={[ 32, 32 ]}>
                        <Col xs={ 24 } sm={ 24 } md={ 12 }>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: 12 }}>
                                <img src={ PUBLIC_URL + '/images/features/signin.png' } alt={ 'signin icon' }/>
                                <Text 
                                    style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 500, fontSize: '16px', lineHeight: '20px' }}
                                >
                                Sign-in to work with ease
                                </Text>
                                <Text>
                                    For both office and field employees creating worry-free clocking in. No RFID hardwares needed with Harmony’s minimal interface   
                                </Text>
                            </div>
                        </Col>
                        <Col xs={ 24 } sm={ 24 } md={ 12 }>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: md ? 'flex-start' : 'flex-end', justifyContent: 'flex-start', gap: 12 }}>
                                <img src={ PUBLIC_URL + '/images/features/teams.png' } alt={ 'teams icon' }/>
                                <Text 
                                    style={{ textAlign: md ? 'left' : 'right', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 500, fontSize: '16px', lineHeight: '20px' }}
                                >
                                    High-performing teams communicate better
                                </Text>
                                <Text style={{ textAlign: md ? 'left' : 'right' }}> With Harmony, you can always have colleagues within reach thanks to the seamless interaction across the many cool features here</Text>
                            </div>
                        </Col>
                        <Col xs={ 24 } sm={ 24 } md={ 12 }>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: 12 }}>
                                <img src={ PUBLIC_URL + '/images/features/leaverequest.png' } alt={ 'leave request icon' }/>
                                <Text 
                                    style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 500, fontSize: '16px', lineHeight: '20px' }}
                                >
                                    Empower employees with self-service leave request processing
                                </Text>
                                <Text>
                                    Get easy leave seeking and approval processing with a few clicks. You and your employees can both forget paperwork   
                                </Text>
                            </div>
                        </Col>
                        <Col xs={ 24 } sm={ 24 } md={ 12 }>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: md ? 'flex-start' : 'flex-end', justifyContent: 'flex-start', gap: 12 }}>
                                <img src={ PUBLIC_URL + '/images/features/locationtaging.png' } alt={ 'location icon' }/>
                                <Text 
                                    style={{ textAlign: md ? 'left' : 'right', fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 500, fontSize: '16px', lineHeight: '20px' }}
                                >
                                    Simple location tagging
                                </Text>
                                <Text style={{ textAlign: md ? 'left' : 'right' }}>Record field visits live from location and upload photos to validate making on field task reports more data-rich, intuitive and allow staff to reflect on their progress</Text>
                            </div>
                        </Col>
                    </Row>
                </Col>
                }

                {/* 3rd */}
                <Col lg={8}>
                    <div>
                        <img style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '100%', height: 'auto' }} src={ xsMd ? PUBLIC_URL + '/images/features/iPhone-11-Pro-Mockup.png' : PUBLIC_URL + '/images/features/iphone-image-2.png' } alt="features" />
                    </div>
                </Col>
            </Row>
        </SectionContainer>
    )
}

// Styles

const HeadingContainer = {
    display: 'flex',
    justifyContent: 'center',
    margin: '0px 0px 32px 0px'
}

const MiddleParagraphWrapper = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    gap: 24
}

const TitleStyles = {
    fontSize: '24px',
    color: '#272727',
    fontWeight: '500',
    lineHeight: '32px',
    textAlign: 'right',
    marginTop: '0px'
}

const ParagraphStyles = {
    fontSize: '12px',
    fontWeight: '400',
    color: '#272727',
    lineHeight: '18.75px',
    textAlign: 'right'
}

export default Feature;