import React from "react";
import {
  Anchor,
  Button,
  Col,
  Divider,
  Row,
  Slider,
  Switch,
  Typography,
} from "antd";
import { useState } from "react";
import { PUBLIC_URL } from "../../App.config";
import { useAntdBreakPoints } from "../../hooks/useAntdBreakPoints";
import { Radio, Tabs } from "antd";

// import constants
const { Text } = Typography;
const { Link } = Anchor;

const PlanSlider = () => {
  const [inputValue, setInputValue] = useState(1);
  const [newCalculatedValue, setNewCalculatedValue] = useState(0);
  const marks = {
    20: {
      style: { color: "#f50" },
      label: <strong></strong>,
    },
    50: {
      style: { color: "#f50" },
      label: <strong></strong>,
    },
    100: {
      style: { color: "#f50" },
      label: <strong></strong>,
    },
    200: {
      style: { color: "#f50" },
      label: <strong></strong>,
    },
  };

  const features = [
    {
      img: PUBLIC_URL + "/images/plans/check.png",
      text: "Attendance",
    },
    {
      img: PUBLIC_URL + "/images/plans/check.png",
      text: "Announcement",
    },
    {
      img: PUBLIC_URL + "/images/plans/cancel.png",
      text: "Expense/Leave Request",
    },
    {
      img: PUBLIC_URL + "/images/plans/cancel.png",
      text: "Payroll",
    },
    {
      img: PUBLIC_URL + "/images/plans/cancel.png",
      text: "Real Time Tracking",
    },
    {
      img: PUBLIC_URL + "/images/plans/cancel.png",
      text: "Free Support",
    },
  ];

  const premiumFeatures = [
    {
      img: PUBLIC_URL + "/images/plans/check_white.png",
      text: "Attendance",
    },
    {
      img: PUBLIC_URL + "/images/plans/check_white.png",
      text: "Announcement",
    },
    {
      img: PUBLIC_URL + "/images/plans/check_white.png",
      text: "Expense/Leave Request",
    },
    {
      img: PUBLIC_URL + "/images/plans/check_white.png",
      text: "Payroll",
    },
    {
      img: PUBLIC_URL + "/images/plans/check_white.png",
      text: "Real Time Tracking",
    },
    {
      img: PUBLIC_URL + "/images/plans/check_white.png",
      text: "Free Support",
    },
  ];

  const onChange = (newValue) => {
    setInputValue(newValue);
    if (newValue <= 20) {
      newValue = newValue * 100;
    } else if (newValue > 20 && newValue <= 50) {
      newValue = newValue * 90;
    } else if (newValue > 50 && newValue <= 100) {
      newValue = newValue * 80;
    } else if (newValue > 100 && newValue <= 200) {
      newValue = newValue * 60;
    } else if (newValue > 200 && newValue <= 500) {
      newValue = newValue * 50;
    }
    console.log({ newValue });
    setNewCalculatedValue(newValue);
  };
  const { xs, sm, md, lg, xl } = useAntdBreakPoints();
  const fontSize = xs
    ? "28px"
    : sm
    ? "32px"
    : md
    ? "32px"
    : lg
    ? "34px"
    : xl
    ? "38px"
    : "49px";
  const display = xs
    ? "none"
    : sm
    ? "none"
    : md
    ? "none"
    : lg
    ? "block"
    : xl
    ? "block"
    : "block";

  const displayCardSlider = xs
    ? "block"
    : sm
    ? "block"
    : md
    ? "block"
    : lg
    ? "none"
    : "none";

  const marginBottom = xs ? "30px" : sm ? "40px" : md ? "93px" : "93px";
  const displayDivider = xs ? "none" : sm ? "none" : md ? "block" : "block";
  const paddingLeft = xs ? "0px" : sm ? "0px" : md ? "20px" : "20px";
  const cardPadding = xs
    ? "26px 30px"
    : sm
    ? "26px 30px"
    : md
    ? "26px 50px"
    : "26px 50px";

  const [mode, setMode] = useState("bdt");

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  const dollar = 108.13;

  return (
    <div className="planSlider" style={{ marginBottom: "30px" }}>
      {/* switch button between bdt and dollar */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "42px",
        }}
      >
        <Radio.Group
          onChange={handleModeChange}
          value={mode}
          style={{
            marginBottom: 8,
            backgroundColor: "rgba(223, 223, 223, 1)",
            padding: "3px",
            borderRadius: "7px",
          }}
        >
          <Radio.Button value="bdt">{"BDT"}</Radio.Button>
          <Radio.Button value="usd">{"USD"}</Radio.Button>
        </Radio.Group>
      </div>

      {/* plans slider */}
      <div style={{ display: display }}>
        <Row>
          <Col span={16} offset={4}>
            <div style={{ marginBottom: "42px" }}>
              <Text style={{ ...titleStyle }}>
                {"How many Users do you have ?"}
              </Text>
            </div>
            <Slider
              min={0}
              max={500}
              marks={marks}
              onChange={onChange}
              tooltip={{
                open: "true",
                placement: "bottom",
                style: {
                  backgroundColor: "#02935e",
                  color: "white",
                  zIndex: 1,
                },
              }}
              value={typeof inputValue === "number" ? inputValue : 0}
            />
          </Col>
        </Row>
      </div>

      {/* cards */}
      <Row style={{ marginTop: "70px" }} gutter={[20, 40]}>
        {/*  features */}
        <Col lg={12} md={24} sm={24}>
          <div
            style={{
              background: "rgba(207, 234, 224, 1)",
              borderRadius: "10px",
              padding: cardPadding,
            }}
          >
            <Row>
              <Col md={12} sm={24}>
                <div
                  style={{
                    ...topButtonStyle,
                    border: "2px solid rgba(2, 166, 106, 1)",
                  }}
                >
                  <Text>{"Free"}</Text>
                </div>
                <h2
                  style={{
                    ...amountStyle,
                    fontSize: fontSize,
                    marginTop: "20px",
                    marginBottom: marginBottom,
                  }}
                >
                  {mode === "bdt" ? "৳" : "$"} {"0"}
                </h2>
                <p style={{ ...textStyle }}>{"Free for Everyone"}</p>
              </Col>

              <Col md={12} sm={24} style={{ display: "flex" }}>
                <Divider
                  type="vertical"
                  style={{
                    backgroundColor: "rgba(170, 191, 183, 1)",
                    height: "100%",
                    width: "2px",
                    display: displayDivider,
                  }}
                />
                <Row style={{ paddingLeft: paddingLeft }} align="middle">
                  {features?.map((item) => (
                    <>
                      <Col span={4}>
                        <img src={item?.img} alt="" />
                      </Col>
                      <Col span={20}>
                        <Text style={{ fontWeight: "500" }}>{item?.text}</Text>
                      </Col>
                    </>
                  ))}
                </Row>
              </Col>
            </Row>
            <Anchor style={{ ...buttonStyle, textAlign: "center" }}>
              <Link
                className="anchorPadding"
                href="https://admin.harmonyhrm.com/register"
                title="Get Started Now"
              />
            </Anchor>
          </div>
        </Col>
        {/* premium features */}
        <Col lg={12} md={24} sm={24}>
          {/* plans slider */}
          <div style={{ display: displayCardSlider, marginBottom: "50px" }}>
            <Row>
              <Col span={16} offset={4}>
                <Text style={{ ...titleStyle }}>
                  {"How many Users do you have ?"}
                </Text>
                <Slider
                  min={0}
                  max={500}
                  marks={marks}
                  onChange={onChange}
                  tooltip={{
                    open: "true",
                    placement: "bottom",
                    style: { backgroundColor: "#02935e", color: "white" },
                  }}
                  value={typeof inputValue === "number" ? inputValue : 0}
                />
              </Col>
            </Row>
          </div>
          <div
            style={{
              background: "rgba(2, 166, 106, 1)",
              borderRadius: "10px",
              padding: cardPadding,
            }}
          >
            <Row>
              <Col md={12} sm={24}>
                <div
                  style={{
                    ...topButtonStyle,
                    border: "2px solid white",
                    padding: "9px 0px",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ color: "white" }}>{"Premium"}</Text>
                </div>
                <h2
                  style={{
                    ...amountStyle,
                    color: "white",
                    marginBottom: "8px",
                    marginTop: "20px",
                    marginBottom: "0px",
                    fontSize: fontSize,
                  }}
                >
                  {mode === "bdt" ? "৳" : "$"}{" "}
                  {mode === "bdt"
                    ? newCalculatedValue
                    : (newCalculatedValue / dollar).toFixed(2)}
                </h2>
                <h4
                  style={{
                    ...mainAmountStyle,
                    color: "white",
                    marginBottom: "0px",
                    marginTop: "20px",
                  }}
                >
                  {mode === "bdt" ? "৳ " : "$ "}
                  {inputValue <= 20
                    ? mode === "bdt"
                      ? "100"
                      : (100 / dollar).toFixed(2)
                    : inputValue > 20 && inputValue <= 50
                    ? mode === "bdt"
                      ? "90"
                      : (90 / dollar).toFixed(2)
                    : inputValue > 50 && inputValue <= 100
                    ? mode === "bdt"
                      ? "80"
                      : (80 / dollar).toFixed(2)
                    : inputValue > 100 && inputValue <= 200
                    ? mode === "bdt"
                      ? "60"
                      : (60 / dollar).toFixed(2)
                    : inputValue > 200 && inputValue <= 500
                    ? mode === "bdt"
                      ? "50"
                      : (50 / dollar).toFixed(2)
                    : "0"}
                </h4>
                <p style={{ ...textStyle, color: "white" }}>
                  {"Per User, Per Month"}
                </p>
              </Col>
              <Col md={12} sm={24} style={{ display: "flex" }}>
                <Divider
                  type="vertical"
                  style={{
                    backgroundColor: "white",
                    height: "100%",
                    width: "2px",
                    display: displayDivider,
                  }}
                />
                <Row style={{ paddingLeft: paddingLeft }} align="middle">
                  {premiumFeatures?.map((item) => (
                    <>
                      <Col span={4}>
                        <img src={item?.img} alt="" />
                      </Col>
                      <Col span={20}>
                        <Text style={{ fontWeight: "500", color: "white" }}>
                          {item?.text}
                        </Text>
                      </Col>
                    </>
                  ))}
                </Row>
              </Col>
            </Row>
            <Anchor
              style={{
                ...buttonStyle,
                backgroundColor: "white",
                color: "rgba(2, 166, 106, 1)",
                textAlign: "center",
              }}
            >
              <Link
                className="anchorPaddingOverview"
                href="https://admin.harmonyhrm.com/register"
                title="Get Started Now"
              />
            </Anchor>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const titleStyle = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "16px",
  color: "rgba(112, 112, 112, 1)",
};

const topButtonStyle = {
  padding: "9px 0px",
  borderRadius: "10px",
  width: "123px",
  // marginBottom: "28px",
  fontWeight: "700",
  fontSize: "17px",
  textAlign: "center",
};

const amountStyle = {
  fontWeight: 700,
  lineHeight: "57px",
};

const mainAmountStyle = {
  fontSize: "30px",
  fontWeight: 700,
  lineHeight: "57px",
};

const textStyle = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "19px",
};

const buttonStyle = {
  backgroundColor: "rgba(2, 166, 106, 1)",
  width: "100%",
  color: "white",
  fontSize: "16px",
  fontWeight: "700",
  height: "41px",
  borderRadius: "10px",
  marginTop: "28px",
};

export default PlanSlider;
