import React from "react";

// Import Components
import { Row, Col, Carousel } from "antd";
import SmallHeading from "../common/SmallHeading";
import CarouselSlide from "./CarouselSlide";
import SectionContainer from "../common/SectionContainer";

//Import Public URL
import { PUBLIC_URL } from "../../App.config";

// Import Hooks
import { useAntdBreakPoints } from "../../hooks/useAntdBreakPoints";

const screenshots = [
  { url: PUBLIC_URL + "/images/sneak-peak/web-1.png", horizontal: true },
  { url: PUBLIC_URL + "/images/sneak-peak/mobile-1.png", horizontal: false },
  { url: PUBLIC_URL + "/images/sneak-peak/web-2.png", horizontal: true },
  { url: PUBLIC_URL + "/images/sneak-peak/mobile-2.png", horizontal: false },
  { url: PUBLIC_URL + "/images/sneak-peak/web-6.png", horizontal: true },
  { url: PUBLIC_URL + "/images/sneak-peak/mobile-3.png", horizontal: false },
  { url: PUBLIC_URL + "/images/sneak-peak/web-4.png", horizontal: true },
  { url: PUBLIC_URL + "/images/sneak-peak/mobile-6.png", horizontal: false },
  { url: PUBLIC_URL + "/images/sneak-peak/web-7.png", horizontal: true },
  { url: PUBLIC_URL + "/images/sneak-peak/mobile-5.png", horizontal: false },
];

// from https://react-slick.neostack.com/docs/example/custom-arrows
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, right: "-64px" }}
      onClick={onClick}
    >
      <img
        src={PUBLIC_URL + "/images/sneak-peak/next-icon.png"}
        alt={"next-button"}
      />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, left: "-64px" }}
      onClick={onClick}
    >
      <img
        src={PUBLIC_URL + "/images/sneak-peak/pre-icon.png"}
        alt={"pre-button"}
      />
    </div>
  );
};

const settings = {
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const SneakPeak = () => {
  // Break Points
  const { xs, sm, md, lg, xl } = useAntdBreakPoints();
  const xsSm = xs || sm;
  const lessPadding = xs || sm || md;

  return (
    <div style={bgStyles} id={"screenshot"}>
      <SectionContainer
        style={{
          ...constainerStyles,
          gap: 8,
          height: "auto",
          padding: xsSm ? "40px 32px 40px 32px" : "60px 195px 60px 195px",
        }}
      >
        <Row gutter={[0, 0]}>
          <div style={titleContainerStyles}>
            <SmallHeading first={"Sneak Peak"} last={"at Harmony"} inline />
          </div>
          <Col lg={24}>
            {lessPadding ? (
              <Carousel
                autoplay
                dots={true}
                dotsClass={"slick-dots slick-thumb"}
                infinite={true}
                speed={500}
                slidesToShow={xsSm || md ? 1 : md || lg ? 2 : xl ? 3 : 4}
                slidesToScroll={xsSm || md ? 1 : md || lg ? 2 : xl ? 3 : 4}
                arrows={lessPadding ? false : true}
                {...settings}
              >
                {screenshots?.map((image, idx) => (
                  <CarouselSlide
                    key={idx}
                    image={image?.url ?? ""}
                    horizontal={image?.horizontal ?? false}
                  />
                ))}
              </Carousel>
            ) : (
              <div style={{ padding: "64px 64px" }}>
                <Carousel
                  autoplay
                  dots={true}
                  dotsClass={"slick-dots slick-thumb"}
                  infinite={true}
                  speed={500}
                  slidesToShow={xsSm || md ? 1 : md || lg ? 2 : xl ? 3 : 4}
                  slidesToScroll={xsSm || md ? 1 : md || lg ? 2 : xl ? 3 : 4}
                  arrows={lessPadding ? false : true}
                  {...settings}
                >
                  {screenshots?.map((image, idx) => (
                    <CarouselSlide
                      key={idx}
                      image={image?.url ?? ""}
                      horizontal={image?.horizontal ?? false}
                    />
                  ))}
                </Carousel>
              </div>
            )}
          </Col>
        </Row>
      </SectionContainer>
    </div>
  );
};

// Styles
const bgStyles = {
  display: "flex",
};

const constainerStyles = {
  width: "100%",
  padding: "50px 195px 0 195px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const titleContainerStyles = {
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: "0px 0px 0px 0px",
};

export default SneakPeak;
