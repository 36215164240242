import React from "react"
// Import Components
import { Image } from "antd"

const CarouselSlide = ({ image, horizontal }) => {
    return (
        <div style={ containerStyles }>
            <Image preview={ true } width={ horizontal ? '320px' : 'auto' } height={ horizontal ? 'auto' : '280px' } src={ image } alt={ 'slide-image' } />
        </div>
    )
}

// JSX Styles
const containerStyles = { 
    minHeight: '400px', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    width: '100%'
}

export default CarouselSlide