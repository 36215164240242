import React, { useEffect, useState } from 'react';
import { getClients } from '../../utils/fetchUtils';
import { Carousel } from 'antd';
import { POCKET_BASE_URL } from '../../App.config';
import './TrustedClients.css';
import SectionContainer from '../common/SectionContainer';
import SmallHeading from '../common/SmallHeading';

const TrustedClients = () => {
    const [clientData, setClientData] = useState([]);

    const getTrustedClients = () => {

        getClients().then(response => {
            if (response.status === 200) {
                setClientData(response?.data?.items ?? [])
            }
        }
        )
    }

    useEffect(() => {
        getTrustedClients()
    }, [])

    // Carousel Settings for Trusted Clients
    const settings = {
        slidesToShow: 4,
        dots: false,
        autoplaySpeed: 1500,
        slidesToScroll: 1,
        cssEase: 'linear',
        draggable: true,
        infinite: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                  slidesToShow: 4,
                }
              },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
      };
    return (
        <SectionContainer id={"trusted-clients"}>
          <div style={innerContainerStyles}>
            <SmallHeading first={"Users who trusted"} last={"Our Platform"} />
          </div>
            <Carousel autoplay className="trustedClientCarousel" {...settings}>
                {
                    clientData?.map((item) => {
                        const imageUrl = `${POCKET_BASE_URL}/api/files/${item?.collectionName}/${item?.id}/${item?.logo}`;

                        return (
                            <div key={item?.id} className="clientSlides">
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <img src={imageUrl} alt="client_images" height={"90%"} width={"95%"} style={{objectFit: "contain"}}/>
                                </div>
                            </div>
                        )
                    })
                }
            </Carousel>
        </SectionContainer>
    );
};

const innerContainerStyles = {
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: "0px 0px 50px 0px",
};

export default TrustedClients;