import PropTypes from 'prop-types'

// Import Components
import { Button, Typography } from 'antd'
import Link from 'antd/es/typography/Link'

// Import Icons
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

const PackageCard = ({ image, name, note, price, scheme, informations, isActive, onActiveButtonClick, symbol }) => {
    const textColor = isActive ? '#FFFFFF' : '#272727'
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div style={{ ...cardContentStyles, backgroundColor: isActive ? '#02A66A' : '#FFFFFF' }}>
                <div style={{ display: 'flex', padding: 8, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <img height={ '70px' } width={ '70px' } src={ image ? image : '' }></img>
                </div>
                <Typography style={{ ...packageNameStyles, color: textColor }}>{ name ?? '' }</Typography>
                <Typography style={{ ...noteStyles, color: textColor }}>{ note ?? '' }</Typography>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography style={{ ...priceStyles, color: textColor }}>{ `${ symbol } ${ price ?? '' } ` }</Typography>
                </div>
                <Typography style={{ ...schemeStyles, color: textColor }}>{ scheme ?? '' }</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 0, padding: '0px 32px' }}>
                    {
                        Object.keys(informations)?.filter((key, idx) => informations[key] ).map((key, idx) => (
                            <div key={ idx } style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 16 }}>
                                { informations[key] 
                                    ? <CheckOutlined style={{ color: isActive ? '#FFFFFF' : '#02A66A' }} /> 
                                    : <CloseOutlined style={{ color: isActive ? '#FFFFFF' : '#F44336' }} /> 
                                }
                                <Typography style={{ ...packageInfoTextStyles, color: textColor }}>{ key ?? '' }</Typography>
                            </div>
                        ))
                    }
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto 0 0 0' }}>
                    <Link href={ '#feedback' } style={{ textAlign: 'center' }}>
                        <Button 
                            onClick={ onActiveButtonClick }
                            style={{
                                ...activeButtonStyles, 
                                backgroundColor: isActive ? '#FFFFFF' : '#02A66A',
                                color: isActive ? '#02A66A' : '#FFFFFF' 
                            }}
                        >
                            { 'Get Started Now' }
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

// JSX Styles
const packageNameStyles = {
    fontFamily: "'Nunito'",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "24px",
    textTransform: "uppercase",
    display: 'flex',
    justifyContent: 'center',
    color: "#272727"
}

const noteStyles = {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#272727"
}

const schemeStyles = {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    color: "#272727"
}

const priceStyles = {
    fontFamily: "'Nunito'",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    color: "#272727",
    display: 'flex',
    justifyContent: 'center'
}

const unitStyles = {
    fontFamily: "'Nunito'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    color: "#272727",
    display: 'flex',
    justifyContent: 'center'
}

const cardContentStyles = { 
    width: '100%', 
    height: '100%', 
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'center',  
    justifyContent: 'flex-start', 
    background: "#FFFFFF", 
    boxShadow: "0px 0px 50px 2px rgba(132, 132, 132, 0.25)",
    borderRadius: "20px",
    padding: '16px 0px', 
    gap: 4  
}

const packageInfoTextStyles = {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#272727"
}

const activeButtonStyles = {
    background: '#02A66A',
    borderRadius: '20px',
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    padding: '24px 0px',
    width: '200px',
    margin: '12px 12px'
}

// Prop Types
PackageCard.propTypes = {
    image: PropTypes.string, 
    name: PropTypes.string,
    price: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]), 
    unit: PropTypes.string, 
    informations: PropTypes.object, 
    isActive: PropTypes.bool, 
    onActiveButtonClick: PropTypes.func,
    symbol: PropTypes.any
}

PackageCard.defaultProps = {
    image: '', 
    name: '',
    price: 0, 
    unit: 'Month', 
    informations: {}, 
    isActive: false, 
    onActiveButtonClick: () => null,
    symbol: '$'
}

export default PackageCard