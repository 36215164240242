import React from 'react'

// Import Components
import { Row, Col, Typography, Button } from 'antd'
import SectionContainer from '../common/SectionContainer'
import LinkButton from '../common/LinkButton'

// Import Public URL
import { PUBLIC_URL } from "../../App.config"

// Import Hooks 
import { useAntdBreakPoints } from '../../hooks/useAntdBreakPoints'

// Import Constants
const { Text } = Typography

const Contacts = () => {

    // Break Points
    const { xs, sm } = useAntdBreakPoints()
    const xsSm = (xs || sm)

    return (
        <div style={ bgStyles } id={ 'contacts' }>
             <SectionContainer containerStyles={{ ...containerStyles, gap: 32 }}>
                <Row 
                    gutter={[ 32, 32 ]} 
                    style={{ display: 'flex', alignItems: xsSm ? 'center' : 'flex-start', justifyContent: 'center', flexDirection: xsSm ? 'column' : 'row' }}
                >
                    <Col xs={ 24 } sm={ 24 } md={ 8 } lg={ 7 }>
                        <div style={{ ...innerContainerStyles, justifyContent: xsSm ? 'center' : 'flex-start' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 8, alignItems: xsSm ? 'center' : 'flex-start' }}>
                                <img height={ 28 } width={ 140 } src={ PUBLIC_URL + '/images/contacts/harmony-logo-3.png' } alt={ 'harmony-logo' } />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 8, alignItems: xsSm ? 'center' : 'flex-start' }}>
                                <Text style={{ width: '100%', textAlign: xsSm ? 'center' : 'left' }}>{ 'hello@barikoi.com' }</Text>
                                <Text style={{ width: '100%', textAlign: xsSm ? 'center' : 'left' }}>{ '+880 1708549077' }</Text>
                            </div>
                            <div style={{ display: 'flex', gap: 28, flexWrap: 'wrap', justifyContent: xsSm ? 'center' : 'flex-start' }}>
                                <Button href='https://bd.linkedin.com/company/barikoi' target='_blank' type={ 'link' } icon={ <img src={ PUBLIC_URL + '/images/contacts/linkedin-icon.png' } alt={ 'linkedin-icon' }/> } />
                                <Button href='https://www.facebook.com/barikoibd/' target='_blank' type={ 'link' } icon={ <img src={ PUBLIC_URL + '/images/contacts/fb-icon.png' } alt={ 'facebook-icon' } /> } />
                                <Button href='https://www.instagram.com/barikoibd/' target='_blank' type={ 'link' } icon={ <img src={ PUBLIC_URL + '/images/contacts/insta-icon.png' } alt={ 'instagram-icon' }/> } />
                            </div>
                        </div>
                    </Col>
                    <Col xs={ 24 } sm={ 24 } md={ 8 } lg={ 5 }>
                        <div style={{ ...innerContainerStyles, justifyContent: xsSm ? 'center' : 'flex-start' }}>
                            <Text style={ sectionTitleTextStyles }>{ 'Useful Link' }</Text>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 8, alignItems: xsSm ? 'center' : 'flex-start'  }}>
                                <LinkButton href={ '#home' } text={ 'Home' } />
                                <LinkButton href={ '#about' } text={ 'About Us' } />
                                <LinkButton href={ '#features' } text={ 'Features' } />
                                <LinkButton href={ '#pricing' } text={ 'Pricing' } />
                                <LinkButton href={ '#contacts' } text={ 'Contact Us' } />
                            </div>
                        </div>
                    </Col>
                    <Col xs={ 24 } sm={ 24 } md={ 8 } lg={ 5 }>
                        <div style={{ ...innerContainerStyles, justifyContent: xsSm ? 'center' : 'flex-start' }}>
                            <Text style={{ ...sectionTitleTextStyles }}>{ 'About Barikoi' }</Text>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 8, alignItems: xsSm ? 'center' : 'flex-start' }}>
                                <LinkButton href={ 'https://www.barikoi.com/ourcompany' } target='_blank' text={ 'About Barikoi' } />
                                <LinkButton href={ 'https://www.barikoi.com/' } target='_blank' text={ 'Barikoi’s Product' } />
                                <LinkButton href={ 'https://www.barikoi.com/termsandservices' } target='_blank' text={ 'Terms & Conditions' } />
                                <LinkButton href={ 'https://www.barikoi.com/data-privacy' } target='_blank' text={ 'Privacy Policy' } />
                            </div>
                        </div>
                    </Col>
                    <Col xs={ 24 } sm={ 24 } md={ 8 } lg={ 7 }>
                        <div style={{ ...innerContainerStyles, justifyContent: xsSm ? 'center' : 'flex-start', paddingBottom: '24px' }}>
                            <Text style={{ ...sectionTitleTextStyles, textAlign: xsSm ? 'center' : 'left' }}>{ 'Get It For Free' }</Text>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 64, alignItems: xsSm ? 'center' : 'flex-start' }}>
                                <Button style={{ width: 'auto' }} href={ 'https://play.google.com/store/apps/details?id=com.barikoi.hrtraceapp&hl=en&gl=US' } target='_blank' type={ 'link' } icon={ <img src={ PUBLIC_URL + '/images/contacts/play_store_logo.png' } alt={ 'playstore-log' } /> } />
                                <Button style={{ width: 'auto' }} href={ 'https://apps.apple.com/us/app/harmony-hr-solution/id6443644004' } target='_blank' type={ 'link' } icon={ <img src={ PUBLIC_URL + '/images/contacts/app_store_logo.png' } alt={ 'applestore-logo' } /> } />
                            </div>
                        </div> 
                    </Col>
                </Row>
            </SectionContainer>
        </div>
    )
}

// Styles
const bgStyles = {
    backgroundImage: `url('/images/contacts/bg-image-3.png'})`,
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    paddding: '0px 0px'
}

const containerStyles = {
    backgroundColor: 'transparent',
}

const innerContainerStyles = {
    display: 'flex', 
    flexDirection: 'column',
    gap: 32
}

const sectionTitleTextStyles = {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    color: "#272727"
}

export default Contacts