import React from "react";

// Import Components
import { Row, Col, Typography, Button, Anchor } from "antd";
import LargeHeading from "../common/LargeHeading";
import SectionContainer from "../common/SectionContainer";

// Import Hooks
import { useAntdBreakPoints } from "../../hooks/useAntdBreakPoints";
import { PUBLIC_URL } from "../../App.config";

// import HeaderMockUpImg from "../../assets/images/home/header_mockup.png"

// Import Constants
const { Paragraph, Text } = Typography;
const { Link } = Anchor;

const Home = () => {
  // Break Points
  const { xs, sm, md, lg, xl } = useAntdBreakPoints();
  const xsSm = xs || sm;
  const fontSize = xs
    ? "28px"
    : sm
    ? "32px"
    : md
    ? "32px"
    : lg
    ? "34px"
    : xl
    ? "38px"
    : "44px";
  const lineHeight = xs
    ? "34px"
    : sm
    ? "36px"
    : md
    ? "36px"
    : lg
    ? "36px"
    : xl
    ? "42px"
    : "56px";

  return (
    <div style={bannerContainerStyles}>
      <SectionContainer id="home">
        <Row
          justify={"center"}
          align={"center"}
          gutter={[32, 32]}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: xsSm ? "column-reverse" : "row",
          }}
        >
          {/* Right */}
          <Col lg={12}>
            <div style={leftContainer}>
              {/* Heading */}
              <div style={{ display: "flex" }}>
                <Text
                  style={{
                    ...firstLineStyles,
                    fontSize: fontSize,
                    lineHeight: lineHeight,
                  }}
                >
                  {" "}
                  {"Harmony"}{" "}
                  <span
                    style={{
                      ...lastLineStyles,
                      fontSize: fontSize,
                      lineHeight: lineHeight,
                    }}
                  >
                    {"- your workplace assistant on the go"}
                  </span>{" "}
                </Text>
              </div>

              {/* Paragraph */}
              <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                <Paragraph style={textStyles}>
                  Harmony is an employee and field force managing platform for
                  businesses which provides end-to-end automated solutions
                  specially catering to unique HR management needs. Harmony is
                  more than a platform - it is a whole experience.
                </Paragraph>
                <Paragraph style={textStyles}>
                  Afterall, we spend nearly a quarter of our day at work,
                  Harmony helps make the most of it. With speed, efficiency, and
                  convenience, your employees can now have access to information
                  at their fingertips and stay up-to-date on the go while admin
                  tasks become fully automated. It is the way to go for
                  future-fit agile workplaces of today for harmonious employee
                  experiences
                </Paragraph>
              </div>

              {/* Button */}

              <div style={buttonWrapperStyles}>
                <div style={buttonStyles}>
                  <Anchor>
                    <Link
                      className="anchorPadding"
                      href="#feedback"
                      title=" Request A Demo"
                    />
                  </Anchor>
                </div>
              </div>
            </div>
          </Col>

          {/* Left */}
          <Col lg={12}>
            <div style={{ width: "100%" }}>
              {/* <img style={{ maxWidth: '100%', height: 'auto' }}  src={HeaderMockUpImg} alt="mockup" /> */}
              <img
                style={{ maxWidth: "100%", height: "auto" }}
                src={PUBLIC_URL + "/images/home/header_mockup.png"}
                alt="mockup"
              />
            </div>
          </Col>
        </Row>
      </SectionContainer>
    </div>
  );
};

// Styles
const bannerContainerStyles = {
  backgroundImage: `url('/images/home/banner_bg.png')`,
  backgroundPosition: "bottom",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  padding: "0px 0px 64px 0px",
};

const leftContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
  gap: 24,
};

const textStyles = {
  color: "#272727",
  fontSize: "16px",
  fontWeight: "400",
  textAlign: "left",
};

const buttonWrapperStyles = {
  display: "flex",
  justifyContent: "flex-start",
};

const buttonStyles = {
  backgroundColor: "#02A66A",
  borderRadius: "20px",
  color: "#fff",
  width: "250px",
  height: "60px",
  fontSize: "22px",
  fontWeight: "700",
  border: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const firstLineStyles = {
  textAlign: "left",
  color: "#272727",
  fontWeight: "500",
  lineHeight: "65.1px",
};

const lastLineStyles = {
  textAlign: "left",
  color: "#02A66A",
  fontWeight: "900",
  lineHeight: "65.1px",
};

export default Home;

// http://localhost:3000/static/media/header_mockup.474089cbaf89e30cfcd2.png
// http://localhost:3000/static/media/header_mockup.474089cbaf89e30cfcd2.png

// 474089cbaf89e30cfcd2
// 474089cbaf89e30cfcd2
