import PropTypes from 'prop-types';
import React from 'react'
import { Typography, Button } from 'antd'

// Constants
const { Text } = Typography

const LinkButton = ({ href, target, text, restProps }) => {
    return (
        <Button 
            style={{ display: 'flex', padding: '0px 0px', height: 'auto' }} 
            type={ 'link' } 
            href={ href ?? '#' }
            target={ target ?? '_self' }
            { ...restProps }
        >
            <Text>{ text ?? '' }</Text>
        </Button>
    )
}

// Props
LinkButton.propTypes = {
    href: PropTypes.string,
    text: PropTypes.string
}

LinkButton.defaultProps = {
    href: '#',
    text: ''
}

export default LinkButton