import React, { useState } from "react";

// Import Components
import { Menu, Grid, Popover, Button, Typography, Anchor } from "antd";

// Import Icons
import harmonyLogo from "../../assets/images/harmony-logo-3.png";
import hamburgerIcon from "../../assets/images/hamburger.png";

// Imports Breakpoints
import { useAntdBreakPoints } from "../../hooks/useAntdBreakPoints";
import { getPadding } from "../../utils/utils";

// Constants
const { useBreakpoint } = Grid;
const { Link } = Anchor;

const Header = () => {
  // States
  const [popoverClicked, setPopoverClicked] = useState(false);

  // Break Points
  const scrs = useAntdBreakPoints();
  const padding = getPadding(scrs);

  const screens = useBreakpoint();
  const xs = Boolean(screens["xs"] === true);
  const sm = Boolean(screens["sm"] === true && screens["md"] === false);
  const xsSm = xs || sm;

  // On Nav Change
  const _onNavChange = () => {
    _onPopupHide();
  };

  // On Popup Hide
  const _onPopupHide = () => {
    setPopoverClicked(false);
  };

  // On Popup Click Change
  const _onPopupClickChange = (open) => {
    setPopoverClicked(open);
  };

  return (
    <div style={{ ...bgStyles, position: "fixed", zIndex: 111 }} id={"header"}>
      <div
        style={{
          ...constainerStyles,
          padding: padding ? padding : "0px 32px 0 32px",
        }}
      >
        <div style={{ ...innerContainerStyles }}>
          <img height={28} width={140} src={harmonyLogo} alt={"harmony-logo"} />
        </div>
        {!xsSm && (
          // added
          <div
            style={{
              margin: "0px 0px 0px auto",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "80%",
            }}
          >
            <Anchor
              targetOffset="50"
              direction="horizontal"
              defaultSelectedKey="home"
            >
              <Link className="navTextStyles" href="#home" title="Home" />
              <Link className="navTextStyles" href="#about" title="About" />
              <Link
                className="navTextStyles"
                href="#features"
                title="Features"
              />
              <Link
                className="navTextStyles"
                href="#screenshot"
                title="Preview"
              />
              <Link className="navTextStyles" href="#pricing" title="Pricing" />
              <Link
                className="navTextStyles"
                href="#feedback"
                title="Feedback"
              />
            </Anchor>
          </div>
        )}
        {xsSm && (
          <div style={{ ...innerContainerStyles, margin: "0px 0px 0px auto" }}>
            <Popover
              placement="bottomRight"
              title={""}
              transitionName={""}
              content={
                <Anchor
                  targetOffset="50"
                  style={{
                    width: "70vw",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                  onClick={_onNavChange}
                >
                  <Link className="navTextStyles" href="#home" title="Home" />
                  <Link className="navTextStyles" href="#about" title="About" />
                  <Link
                    className="navTextStyles"
                    href="#features"
                    title="Features"
                  />
                  <Link
                    className="navTextStyles"
                    href="#screenshot"
                    title="Preview"
                  />
                  <Link
                    className="navTextStyles"
                    href="#pricing"
                    title="Pricing"
                  />
                  <Link
                    className="navTextStyles"
                    href="#feedback"
                    title="Feedback"
                  />
                </Anchor>
              }
              open={popoverClicked}
              onOpenChange={_onPopupClickChange}
              trigger="click"
            >
              <Button
                ghost
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "16px 16px",
                  margin: "0px 0px",
                }}
                icon={
                  <img
                    height={"16px"}
                    width={"20px"}
                    style={{ padding: "0px 0px", margin: "0px 0px" }}
                    src={hamburgerIcon}
                    alt={"expand-menu-items-icon"}
                  />
                }
              />
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
};

// Styles
const bgStyles = {
  display: "flex",
  width: "100%",
  backgroundColor: "#fff",
  zIndex: 1000,
};

const constainerStyles = {
  width: "100%",
  height: "80px",
  margin: "0 auto",
  padding: "0px 195px 0 195px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
};

const innerContainerStyles = {
  display: "flex",
  gap: 32,
  alignItems: "center",
};

export default Header;
