import { Typography } from "antd"

const ContactOption = ({ image, contact, value }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 32 }}>
            <img src={ image } />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography style={ keyTextStyles }>{ contact ?? '' }</Typography>
                <Typography style={ valueTextStyles }>{ value ?? '' }</Typography>
            </div>
        </div>
    )
}

// JSX Styles
const keyTextStyles = {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "26px",
    color: "#272727"
}

const valueTextStyles = {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    color: "#272727"
}

export default ContactOption