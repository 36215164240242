import React from "react";

// Import Components
import { Row, Col, Typography, Button, Anchor } from "antd";
import SectionContainer from "../common/SectionContainer";
import SmallHeading from "../common/SmallHeading";

// Import Public URL
import { PUBLIC_URL } from "../../App.config";

// Import Hooks
import { useAntdBreakPoints } from "../../hooks/useAntdBreakPoints";

// Import Constants
const { Paragraph } = Typography;
const { Link } = Anchor;

const Overview = () => {
  // Break Points
  const { xs, sm } = useAntdBreakPoints();
  const xsSm = xs || sm;

  return (
    <div style={bgStyles} id={"overview"}>
      <SectionContainer>
        <Row
          gutter={[32, 32]}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: xsSm ? "column-reverse" : "row",
          }}
        >
          <Col lg={12}>
            <div style={leftSectionContainerStyles}>
              <SmallHeading
                first={"Dedicated friends for"}
                last={"onboarding and usage support"}
              />
              <div>
                <div>
                  <Paragraph style={paragraphStyles}>
                    {`We know HRMS isn’t a one-size fits all solution, which is why with Harmony we welcome customizations catering to your business needs. Our dedicated support team is open to serve and build you a powerful interface with rich data that fits your use cases.`}
                  </Paragraph>
                </div>
                <div>
                  <Paragraph style={paragraphStyles}>
                    {`Request a demo today or call for assistance - at Harmony we’re in sync with you.`}
                  </Paragraph>
                </div>
              </div>
              <div>
                <Anchor style={requestDemoButtonStyles}>
                  <Link
                    className="anchorPaddingOverview"
                    href="#feedback"
                    title=" Request A Demo"
                  />
                </Anchor>
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <img
              style={{ maxWidth: "100%", height: "auto" }}
              src={PUBLIC_URL + "/images/overview/hr_trace_web_scr_1.png"}
              alt="hr_trace_image_1"
            />
          </Col>
        </Row>
      </SectionContainer>
    </div>
  );
};

// Styles
const bgStyles = {
  backgroundImage: `url('/images/overview/request-demo-bg.png')`,
  backgroundPosition: "bottom",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  display: "flex",
  padding: "40px 0px",
};

const leftSectionContainerStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: 32,
};

const paragraphStyles = {
  fontFamily: "'Roboto'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "19px",
  color: "#272727",
};

const requestDemoButtonStyles = {
  width: "66%",
  height: "60px",
  fontFamily: "'Roboto'",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "22px",
  color: "#02A66A",
  border: "1px solid #02A66A",
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default Overview;
