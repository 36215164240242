import React from "react";
import { message } from "antd";

// Import Components
import { Button, Form, Input, Select } from "antd";

// Actions and Methods
import { requestDemo } from "../../utils/fetchUtils";

// Constants
const { Item } = Form;

const FeedbackForm = () => {
  // Antd Use From Hook
  const [form] = Form.useForm();

  // On Finish
  const _onFinish = (values) => {
    requestDemo(values)
      .then((res) => {
        message.success({
          content: res?.data?.message ?? "",
          key: "request-demo",
        });
        form.resetFields();
      })
      .catch((err) => {
        console.log({ err });
        message.success({
          content:
            err?.res?.message ?? "Something Went Wrong, Please Try Again !",
          key: "request-demo",
        });
      });
  };

  // On Finish Failed
  const _onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      onFinish={_onFinish}
      onFinishFailed={_onFinishFailed}
      className={"feedbackForm"}
    >
      <Item
        name="name"
        rules={[{ required: true, message: "This field is Required!" }]}
      >
        <Input size={"large"} placeholder="Name" />
      </Item>
      <Item
        name="email"
        rules={[
          { required: true, message: "This field is Required!" },
          { type: "email", message: "The input is not valid E-mail!" },
        ]}
      >
        <Input size={"large"} placeholder="Email" />
      </Item>
      <Item
        name="phone"
        rules={[
          { required: true, message: "This field is Required!" },
          {
            pattern: new RegExp(/^(?:(?:\+|00)88|01)?\d{11}$/),
            message: "Invalid Phone Number",
          },
        ]}
      >
        <Input size={"large"} placeholder="Phone" />
      </Item>
      <Item
        name="company_name"
        rules={[{ required: true, message: "This field is Required!" }]}
      >
        <Input size={"large"} placeholder="Company Name" />
      </Item>
      <Item
        name="emplayee_count"
        rules={[
          { required: true, message: "This field is Required!" },
          { pattern: new RegExp(/^[0-9]*$/), message: "Must be a Number" },
        ]}
      >
        <Input size={"large"} placeholder="Employee Count" />
        {/* <Select
                    size={ 'large' }
                    placeholder="Employee Count"
                    onChange={ () => null }
                    options={[
                        {
                            value: '5',
                            label: '1-5'
                        },
                        {
                            value: '20',
                            label: '6-20'
                        }
                    ]}
                    allowClear
                /> */}
      </Item>
      <Item
        name="how_do_you_know"
        rules={[{ required: true, message: "This field is Required!" }]}
      >
        <Select
          size={"large"}
          placeholder="How did you know about Harmony ?"
          onChange={() => null}
          options={[
            {
              value: "Friends",
              label: "From Friends",
            },
            {
              value: "Google Search",
              label: "Google Search",
            },
            {
              value: "Others",
              label: "Others",
            },
          ]}
          allowClear
        />
      </Item>
      <Item wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 10 }}>
        <Button type="primary" htmlType="submit" style={submitButtonStyles}>
          {"Request Demo"}
        </Button>
      </Item>
    </Form>
  );
};

// Submit Button Styles
const submitButtonStyles = {
  background: "#02A66A",
  borderRadius: "20px",
  fontFamily: "'Roboto'",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "22px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#FFFFFF",
  padding: "24px 0px",
  width: "100%",
  margin: "0px 0px 0px 0px",
};

export default FeedbackForm;
